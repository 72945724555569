import React from 'react';
import { useNavigate } from "react-router-dom";

import LogoImg from '../img/flwrs-logo.png';

export default function LogoLink() {
  const navigate = useNavigate();

  return (
    <img
      src={LogoImg}
      onClick={ () => { navigate("/"); } }
      className="logo"/>
  )
}
