import React from 'react';
import { useNavigate } from "react-router-dom";

import '../base.css'

import LogoImg from '../../img/flwrs-logo.png'

const Main = () => {
  const navigate = useNavigate()

  return (
    <div className="main">
      <img
        src={LogoImg}
        onClick={ () => { navigate("/"); } }
        className="logo"/>

      <div className='body'>
        <div className="masthead">
          <img src="https://cdn.assets.lomography.com/71/ede6ccb31ea12700c38451682c546805e4b311/1280x902x1.jpg?auth=a0cf46c30c7a2d6c6a06b8dabd0c4a51e88bbb45"/>
        </div>
        <div className="content">

        </div>
      </div>
    </div>
  );
};
export default Main;