import React from 'react';

export default function Overlay() {
  const data = [
    { id: 1, href: "https://github.com/flowerpetalsofdoom", icon: "fab fa-github" },
    { id: 2, href: "https://www.instagram.com/flowerpetalsofdoom", icon: "fab fa-instagram" },
    { id: 3, href: "http://codepen.io/flwrs/", icon: "fab fa-codepen" },
    { id: 4, href: "mailto:hello@atiqah.com", icon: "far fa-envelope" },
  ];

  const links = data.map(link =>
    <a key={link.id} className="link" href={link.href} target="_blank"><i className={link.icon}></i></a>
  );

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>flwrs —</div>
      <a href="https://atiqah.com/"  target="_blank" style={{ position: 'absolute', bottom: 58, left: 90, fontSize: '13px' }}>
        atiqah.com
      </a>
      <a href="https://www.instagram.com/flowerpetalsofdoom/"  target="_blank" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
        @flowerpetalsofdoom
      </a>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '1.5rem' }}>
        {links}
      </div>
    </div>
  )
}
