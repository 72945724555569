import { createRoot } from 'react-dom/client'
import App from './App'

import './styles.css'
import './all.min.css' // fontawesome icons

createRoot(document.getElementById('root')).render(
  <>
    <App />
  </>
)
