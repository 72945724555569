import React from 'react';
import { useNavigate } from "react-router-dom";

import LogoLink from '../commons/Logo.js';

import './main.css'

const Main = () => {
  const navigate = useNavigate()

  return (
    <div className="main">
      <LogoLink />
      <div className="header">
        FILM
      </div>

      <div className='content'>
        <img src="https://cdn.assets.lomography.com/71/ede6ccb31ea12700c38451682c546805e4b311/1280x902x1.jpg?auth=a0cf46c30c7a2d6c6a06b8dabd0c4a51e88bbb45"
          onClick={ () => { navigate("/film/india"); } }/>
        <img src="https://cdn.assets.lomography.com/de/23645a802cc4f9f316907976f3315ad650bfea/1280x848x1.jpg?auth=42d0fa30afe0b673e0e2c0e249b4aefc10ad3033"/>
        <img src="https://cdn.assets.lomography.com/b8/9a5e09c28c9f8ea81a2df9939c139b4ad33a93/1545x1024x1.jpg?auth=51104c12956b06ca5904d8457d84f6d9840c4035"/>
        <img src="https://cdn.assets.lomography.com/ce/60dfa3f14765cd74cf28ae8fa8f589e899fb06/2371x1589x2.jpg?auth=04bad0b1d3b9121187bf940087894b39a708ae7b"/>
        <img src="https://cdn.assets.lomography.com/79/d9f35643fe14dc36132a7939df55373eeb2dde/1840x1232x2.jpg?auth=66697a6ac314df22b1184fc7c795567434c2705b"/>
        <img src="https://cdn.assets.lomography.com/da/7d716c233f6bbeac06bedcbb62b1f2d88bbb61/753x1614x2.jpg?auth=de79ba9217349dd10f54fd7f8515e58d6cb78230"/>
      </div>
    </div>
  );
};
export default Main;