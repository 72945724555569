// Preload images
import imagesLoaded from "imagesloaded";
import Lenis from 'lenis';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Define a variable that will store the Lenis smooth scrolling object
let lenis;

const preloadImages = (selector = 'img') => {
  return new Promise((resolve) => {
      imagesLoaded(document.querySelectorAll(selector), {background: true}, resolve);
  });
};

// Helper function that lets you dynamically figure out a grid's rows/columns as well as further refine those with "odd" or "even" ones
// https://greensock.com/forums/topic/34808-how-can-i-animate-the-odd-and-even-columns-rows-of-a-grid-with-gsapto/?do=findComment&comment=174346
const getGrid = selector => {
  let elements = gsap.utils.toArray(selector),
    bounds,
    getSubset = (axis, dimension, alternating, merge) => {
        let a = [],
          subsets = {},
          onlyEven = alternating === "even",
          p;
      bounds.forEach((b, i) => {
        let position = Math.round(b[axis] + b[dimension] / 2),
          subset = subsets[position];
        subset || (subsets[position] = subset = []);
        subset.push(elements[i]);
      });
      for (p in subsets) {
        a.push(subsets[p]);
      }
      if (onlyEven || alternating === "odd") {
        a = a.filter((el, i) => !(i % 2) === onlyEven);
      }
        if (merge) {
        let a2 = [];
        a.forEach(subset => a2.push(...subset));
        return a2;
        }
        return a;
    };
  elements.refresh = () => bounds = elements.map(el => el.getBoundingClientRect());
  elements.columns = (alternating, merge) => getSubset("left", "width", alternating, merge);
  elements.rows = (alternating, merge) => getSubset("top", "height", alternating, merge);
  elements.refresh();

  return elements;
}

// Function to initialize Lenis for smooth scrolling
const initSmoothScrolling = () => {
  // Instantiate the Lenis object with specified properties
  lenis = new Lenis({
    lerp: 0.15, // Lower values create a smoother scroll effect
    smoothWheel: true // Enables smooth scrolling for mouse wheel events
  });

  // Update ScrollTrigger each time the user scrolls
  lenis.on('scroll', () => ScrollTrigger.update());

  // Define a function to run at each animation frame
  const scrollFn = (time) => {
    lenis.raf(time); // Run Lenis' requestAnimationFrame method
    requestAnimationFrame(scrollFn); // Recursively call scrollFn on each frame
  };
  // Start the animation frame loop
  requestAnimationFrame(scrollFn);
};

const scroll = (grid) => {
  const viewportHeight = window.innerHeight;
  const endValue = viewportHeight / 2;

  // Loop through each grid item to add animations
  grid(".grid__item").forEach((item, index) => {
    // Get the previous element sibling for the current item
    const previousElementSibling = item.previousElementSibling;
    // Determine if the current item is on the left side based on its position relative to the previous item
    const isLeftSide = previousElementSibling && (item.offsetLeft + item.offsetWidth <= previousElementSibling.offsetLeft + 1);
    // Determine the origin for transformations (either 100 or 0 depending on position)
    const originX = isLeftSide ? 100 : 0;

    gsap
    .timeline({
      defaults: {
        //duration: 1,
        ease: 'power4'
      },
      scrollTrigger: {
        trigger: item,
        start: 'top bottom-=15%',
        end: '+=100%',
        scrub: true
      }
    })
    .fromTo(item.querySelector('.grid__item-img'), {
      scale: 0,
      transformOrigin: `${originX}% 0%`
    }, {
      scale: 1
    })
    .fromTo(item.querySelector('.grid__item-img-inner'), {
      scale: 5,
      transformOrigin: `${originX}% 0%`
    }, {
      scale: 1
    }, 0)
    .fromTo(item.querySelector('.grid__item-caption'), {
      xPercent: () => isLeftSide ? 100 : -100,
      opacity: 0
    }, {
      ease: 'power1',
      xPercent: 0,
      opacity: 1
    }, 0);

  });
}

export {
  preloadImages,
  getGrid,
  initSmoothScrolling,
  scroll
};