import { useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import gsap from 'gsap';
import { preloadImages, initSmoothScrolling, scroll } from './utils.js';

import './base.css'

import LogoLink from '../commons/Logo.js';


export default function Film() {
  const navigate = useNavigate();

  const data = [
    { path: "/img/2024-02-india-1.jpg", href: "chennai", title: "chennai", date: "feb 2024", c: 1, s: 4},
    { path: "/img/2024-01-bali.jpg", title: "bali", date: "jan 2024", c: 5, s: 3},
    { path: "/img/2023-10-kl-1.jpg", title: "kl", date: "oct 2023", c: 3, s: 2},
    { path: "/img/2023-09-krabi-1.jpg", title: "krabi", date: "sep 2023", c: 1, s: 2},
    { path: "/img/2023-08-matd-1.jpg", title: "Make This A Double Vol. 3", date: "aug 2023", c: 3, s: 5},
    { path: "/img/2023-08-jakarta-1.jpg", title: "curug cikondang", date: "aug 2023", c: 1, s: 2},
    { path: "/img/2023-04-thailand-1.jpg", title: "bkk", date: "apr 2023", c: 3, s: 3},
    { path: "/img/2023-01-turkey-1.jpg", title: "istanbul", date: "jan 2023", c: 6, s: 2},
    { path: "/img/2022-12-sa-1.jpg", title: "johannesburg", date: "dec 2022", c: 1, s: 5},
    { path: "/img/2022-10-jb-1.jpg", title: "jb", date: "oct 2022", c: 6, s: 3},
    { path: "/img/2022-08-bali-1.jpg", title: "bali", date: "aug 2022", c: 4, s: 2},
    { path: "/img/2022-01-raya-1.jpg", title: "raya", date: "jan 2022", c: 1, s: 3},
    { path: "/img/2021-08-matd-1.jpg", title: "Make This A Double Vol. 1", date: "aug 2021", c: 4, s: 4},
    { path: "/img/2021-08-matd-2.jpg", title: "Make This A Double Vol. 1", date: "aug 2021", c: 1, s: 3},
    { path: "/img/2021-07-lazarus-1.gif", title: "lazarus", date: "jul 2021", c: 4, s: 2},
    { path: "/img/2021-07-1.jpg", title: "Looking Point", date: "jul 2021", c: 6, s: 2},
    { path: "/img/2021-01-gillman-1.jpg", title: "Gillman Barracks", date: "jan 2021", c: 3, s: 3},
    { path: "/img/2020-03-1.jpg", title: "lombok", date: "mar 2020", c: 1, s: 2},
    { path: "/img/2020-01-sam-1.jpg", title: "SAM", date: "jan 2020", c: 3, s: 4},
    { path: "/img/2020-01-n2l-1.jpg", title: "Light to Night", date: "jan 2020", c: 7, s: 2},
    { path: "/img/2020-01-aliwal-1.jpg", title: "Aliwal", date: "jan 2020", c: 4, s: 3},
    { path: "/img/2019-xian.jpg", title: "Xi'An", date: "aug 2019", c: 1, s: 3},
    { path: "/img/2018-lauv.jpg", title: "Lauv Concert", date: "2018", c: 4, s: 3},
    { path: "/img/2018-chinatown.jpg", title: "Chinatown at dusk", date: "2018", c: 7, s: 2},
    { path: "/img/2014-fed.jpg", title: "Fed-2 Test", date: "2014", c: 3, s: 4},
  ];
  const images = data.map((image, i) =>
    <figure className="grid__item" style={{ "--r": i+1, "--c": image.c, "--s": image.s }} key={i}>
      <div className="grid__item-img">
          <div className="grid__item-img-inner"
            // onClick={ () => { navigate("/film/" + image.href); } }
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL + image.path} )` }}></div>
      </div>
      <figcaption className="grid__item-caption">
        <h3>{image.title}</h3>
        <span>{image.date}</span>
      </figcaption>
    </figure>
  );

  // Get the grid element
  const el = useRef();
  const grid = gsap.utils.selector(el);

  useEffect(() => {
    // Preload images, initialize smooth scrolling, apply scroll-triggered animations, and remove loading class from body
    preloadImages('.grid__item-img-inner').then(() => {
      initSmoothScrolling();
      scroll(grid);

      // document.body.classList.remove('loading');
      const body = document.querySelector(".body");
      body.classList.remove('loading');
    });
  }, []);

  return (
    <div className="film">
      <LogoLink />

      <div className="body loading">
        <main>
          <div className="grid" ref={el}>
            {images}
          </div>
        </main>
      </div>


      <iframe className="instagram-media instagram-media-rendered" id="instagram-embed-1" src="https://www.instagram.com/sadgirlfilms/embed/" allowtransparency="true" allowfullscreen="true" frameborder="0" height="387" data-instgrm-payload-id="instagram-media-payload-1" scolling="no"></iframe>
    </div>
  )
}
