import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import Home from './Home'
import Main from './Main'
import Film from './Film'

import India from './Film/pages/india-2-24'

export default function App() {
  return (
    <>
      <Router>
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/main" element={<Main />} />
              <Route path="/film/india" element={<India />} />
            <Route path="/film" element={<Film />} />
          </Routes>
        </>
      </Router>
    </>
  )
}


/*
TODO
Cursor
- gooey https://tympanus.net/Development/GooeyCursor/index3.html
- refraction https://tympanus.net/codrops/2023/10/10/progressively-enhanced-webgl-lens-refraction/

Tech projects
https://tympanus.net/codrops/2023/06/21/coding-the-sliced-image-hover-effect-from-quai-network/

Reflection scroll
https://tympanus.net/Development/ReflectionScroll/index2.html
*/